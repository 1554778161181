.App{
  width: 100vw;
  height: 100vh;
}
body{
  margin: 0;
  padding: 0;
}
.home{
  position: relative;
  display: 'flex';
  align-self: 'center';
  margin: auto;
  margin-top: 120px;
  margin-bottom: 120px;
  background-image: url(components/stuff/pics/map.jpg);
  height: 1200px;
  width: 1200px;
}
.credits{
  font-family: 'Courier New', Courier, monospace;
  font-style: italic;
  font-size: medium;
  padding-top: 190px;
  padding-left: 200px;
  padding-right: 200px;
}
table {
  border: 4px solid forestgreen;
}
td {
  text-align: center;
  border: 1px solid black;
}
.antworten{
  list-style: upper-latin;
}
.cont{
  background-color: rgb(124, 218, 148);
  width: 100%;
  height: 100px;
  display: 'flex';
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}
.langCont{
  margin-left: 20px;
  float: left;
  width: auto;
}
.langCont>*{
  height: 50px;
  display: inline-block;
}
.langCont>* *{
  float: left;
  height: 100%;
  margin-right: 7px;
}
.langCont img {
 height: 100%;
}
.exButton{
  background-color: rgb(253, 116, 66);
  padding: 2px 10px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  color: black;
  border-radius: 15px;
  box-shadow: 0 2px #999;
}
.exButton:hover {
  background-color:  rgb(224, 75, 21);
}
.exButton_inactive{
  background-color: rgb(185, 117, 93);
  padding: 2px 10px;
  font-size: 13px;
  text-align: center;
  color: black;
  border-radius: 15px;
  box-shadow: 0 2px #999;
}
.exercises{
  position: absolute;
  top: 20%;
  left: 15%;
  width: 70%;
  padding-bottom: 70px;
}
.homeButton {
  padding: 5px 15px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #228b26;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}
.pretButton {
  padding: 1px 6px;
  margin: 3px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  background-color: #ffffff;
  border-color: green;
  border-radius: 5px;
  border-width: 2px;
}
.pretButton:hover {
  background-color:  rgb(124, 218, 148);
}
.homeButton:hover {
  background-color: #1e7921;
}
.homeButton:active {
  background-color: #2b772d;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
